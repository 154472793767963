import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { mainStore, pixStore } from '@/store';
let PixIntegradorExibirDetalhesPix = class PixIntegradorExibirDetalhesPix extends Vue {
    constructor() {
        super(...arguments);
        this.dialog = false;
        this.loading = false;
        this.loadingConsultaStatus = false;
        this.pixDados = {};
        this.valorAtualizado = null;
        this.novoValorAtualizado = null;
        this.permiteEditarValor = false;
        this.editValorAtualizadoMode = false;
        this.mode = 1;
        this.txid = null;
        this.e2e_id = null;
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    // Implementar no futuro
    // get allowConciliar() {
    //   return !this.pixDados.financeiro_parcela_id && this.pixDados.info_adicionais;
    // }
    // public async conciliar() {
    // }
    async show(companyConfiguracaoIntegracaoBancariaId, txid, e2e_id) {
        this.dialog = true;
        this.loading = true;
        this.pixDados = {};
        this.txid = txid;
        this.e2e_id = e2e_id;
        if (this.txid) {
            this.pixDados = await pixStore.pixCobrancaConsultar({
                company_configuracao_integracao_bancaria_id: companyConfiguracaoIntegracaoBancariaId,
                txid,
            });
        }
        else {
            this.pixDados = await pixStore.pixConsultar({
                company_configuracao_integracao_bancaria_id: companyConfiguracaoIntegracaoBancariaId,
                e2e_id,
            });
        }
        this.loading = false;
    }
    async close() {
        this.dialog = false;
        this.pixDados = {};
    }
};
PixIntegradorExibirDetalhesPix = __decorate([
    Component
], PixIntegradorExibirDetalhesPix);
export default PixIntegradorExibirDetalhesPix;
